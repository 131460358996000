import { Component } from '@angular/core';

@Component({
  selector: 'app-fastdelivery',
  templateUrl: './fastdelivery.component.html',
  styleUrls: ['./fastdelivery.component.css']
})
export class FastdeliveryComponent {

}
