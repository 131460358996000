<div class="container-fluid text-center bg-breadCrumb py-5 ">
    <div class="row">
        <h1 class=" sColor ">Complaint Handling</h1> </div>
</div>
<!-- Page content {START} -->
<section>
    <div class="container py-5">
      <div class="row">
        <div class="row">
          <div class="col-md-9 py-3">
            <p>Your concerns will be fully investigated and a detailed response (‘decision letter’) will be issued within 15 business days of receiving your complaint.</p>
            <p>For a thorough investigation to be undertaken your complaint letter and details from your file will be sent to the Agent, who will be asked for a report.</p>
            <p>Should you have any concerns in the meantime you should contact the member of staff whose name appears on the acknowledgement letter.</p>
            <p>In exceptional situations, if the answer cannot be given within 15 business days for reasons beyond our control we will send a holding reply, clearly indicating the reasons for the delay in responding to the complaint and specifying the deadline by which we will receive the final reply. In any event, the deadline for receiving the final reply shall not exceed 35 business days.</p>
            <p>SendSii is committed to resolving any customer disputes fairly and efficiently If you are still dissatisfied with how we have dealt with your complaint or the outcome of it, you have a right to refer your complaint to the <strong>Financial Ombudsman Service (FOS)</strong> for independent resolution at 183 Marsh Wall, London E14 9SR, Tel: 0800 0234 567, Email: complaint.info@financial-ombudsman.org.uk</p>
          </div>
        </div>
      </div>
    </div>
  
  </section>
  <!-- Page content {END} -->