<div class="container-fluid text-center bg-breadCrumb py-5 ">
    <div class="row">
        <h1 class=" sColor ">Become An Agent</h1> </div>
</div>
<!-- Page content {START} -->
<section>
    <div class="container py-5">
      <div class="row">
        <div class="row">
          <div class="col-md-9 py-3">
            <h1>Join Our Global Network</h1>
            <p>Partner with SendSii to become an agent and grow your business. By joining, you’ll offer your customers an essential service—international money transfers. We provide full support, including technology, training, and ongoing assistance.</p>
            <div>
                <!-- <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdqGQvnYJyMS-8-H7yRzjoxm7xpgaA9FATfeswZuh-znnmAIQ/viewform?embedded=true" width="640" height="2000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> -->
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSebKAokVaTBTpVq0qlh9yP53w0BJS_MazisoDLLZyDzf_G2yw/viewform?embedded=true" width="640" height="1725" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </section>
  <!-- Page content {END} -->