<div class="container-fluid text-center bg-breadCrumb py-5 ">
    <div class="row">
        <h1 class=" sColor ">Find Agent</h1> </div>
</div>
<!-- Page content {START} -->
<section>
    <div class="container py-5">
      <mat-spinner  [diameter]="50" mode="indeterminate" *ngIf="showProgress" style="margin: 0px auto">   </mat-spinner>
      <div class="row">
        <div class="col-md-4">
          <div class="py-3">
            <form [formGroup]="form" *ngIf="form">
              <select *ngIf="!showProgress" class="form-select" aria-label="Default select example" formControlName="txtLocation" (change)="onSelectLocation($event)">
                <option [ngValue]="null" [selected]>Filter by Location...</option>
                <option *ngFor="let city of cityList" value="{{city.agentCity}}">{{city.agentCity}}</option>
              </select><div class="input-group mb-3">
              </div>
            </form>
          </div>
        </div>
        </div>
        <div class="row">
          <div class="col-md-6 py-3" *ngFor="let agent of filterAgentList">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">{{agent.agentType}}</h5>
                <h5 class="card-subtitle mb-2 text-dark">{{agent.agentName}}</h5>
                <p class="card-text"><strong>Address: </strong>{{agent.agentAddress}} - {{agent.agentCity}}</p>
                <p class="card-text"><strong>Working Days: </strong>{{agent.agentWorkingDays}}</p>
                <p class="card-text"><strong>Working Time: </strong>{{agent.agentWorkingTime}}</p><br>
                <div class="me-4 float-start"><strong>Tel: </strong><a href="tel:+{{agent.agentMobile}}" class="card-link">+{{agent.agentMobile}}</a></div>
                <div class="me-4 float-start"><strong>Email: </strong><a href="mailto:{{agent.agentEmail}}" class="card-link">{{agent.agentEmail}}</a></div>
                <strong></strong><a href="{{agent.agentMapLocation}}" target="_blank" class="card-link">Map Location</a>
              </div>
            </div>
          </div>
        </div>
    </div>
  
  </section>
  <!-- Page content {END} -->