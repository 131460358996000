import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './common/home/home.component';
import { ContactusComponent } from './common/contactus/contactus.component';
import { AboutusComponent } from './common/aboutus/aboutus.component';
import { HowitworksComponent } from './common/howitworks/howitworks.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { ComplainthandlingComponent } from './common/complainthandling/complainthandling.component';
import { RefundCancellationPolicyComponent } from './common/refund-cancellation-policy/refund-cancellation-policy.component';
import { BecomeagentComponent } from './common/becomeagent/becomeagent.component';
import { FindagentComponent } from './common/findagent/findagent.component';
import { ServicesComponent } from './common/services/services.component';
import { FaqComponent } from './common/faq/faq.component';
import { SecuretransferComponent } from './common/securetransfer/securetransfer.component';
import { AmlcomplianceComponent } from './common/amlcompliance/amlcompliance.component';
import { FastdeliveryComponent } from './common/fastdelivery/fastdelivery.component';

const routes: Routes = [
  // {path: '', loadComponent: () => import('./common/home/home.component').then(mod => mod.HomeComponent)},
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'aboutus', component: AboutusComponent},
  {path: 'contactus', component: ContactusComponent},
  {path: 'howitworks', component: HowitworksComponent},
  {path: 'complaint-handling', component: ComplainthandlingComponent},
  {path: 'refund-cancellation-policy', component: RefundCancellationPolicyComponent},
  {path: 'agent-signup', component: BecomeagentComponent},
  {path: 'find-agent', component: FindagentComponent},
  {path: 'services', component: ServicesComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'secure-fast', component: SecuretransferComponent},
  {path: 'aml-compliance', component: AmlcomplianceComponent},
  {path: 'fast-delivery', component: FastdeliveryComponent},
  {
    path: '**',
    component: PageNotFoundComponent,
    pathMatch: 'full'   
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
