<div class="container-fluid text-center bg-breadCrumb py-5 ">
    <div class="row">
        <h1 class=" sColor ">Contact Us</h1>
    </div>
</div>

<!-- Send money in four easy steps {START} -->
<section>
    <div class="container py-5">
        <div class="row mb-5">
            <div class="col-md-8"><iframe
                    src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d4962.747574918!2d-0.1989743!3d51.5430446!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x48761071c5dc8b0f%3A0xd3045fe0705c9183!2sNW6%202BS%20Kilburn%20High%20Rd%20London!3m2!1d51.543044599999995!2d-0.1989743!5e0!3m2!1sen!2sin!4v1683268494253!5m2!1sen!2sin"
                    width="700" height="500" style="border:0;" allowfullscreen="yes" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe> 
                
                               
                </div>

            <div class="col-md-4 py-3 border">
                <div class="d-flex align-content-center border-bottom py-3">
                    <div class=" flex-grow-1 ms-3">
                        <h5 class="text-start d-block fw-semibold text-dark">Get In Touch</h5>
                        <p>For any inquiries, reach out to us by email, phone, or visit our nearest agent location. We’re always ready to help you with your money transfer needs.</p>
                    </div>
                </div>
                <div class="d-flex align-content-center border-bottom py-3">
                    <div class="flex-shrink-0">
                        <i class="fa fa-map-marker sColor" aria-hidden="true"></i>
                    </div>
                    <div class=" flex-grow-1 ms-3">
                        <h5 class="text-start d-block fw-semibold text-dark">Address</h5>
                        <p class="text-start"> 250 Kilburn High Road
                            London, NW6 2BS</p>
                    </div>
                </div>

                <div class="d-flex border-bottom py-3">
                    <div class="flex-shrink-0 ">
                        <i class="fa fa-phone sColor" aria-hidden="true"></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h5 class="text-start d-block fw-semibold text-dark">Phone</h5>
                        <p class="text-start"> <a href="tel:+4420 3371 0202">+4420 3371 0202</a> </p>
                    </div>
                </div>

                <div class="d-flex py-3">
                    <div class="flex-shrink-0">
                        <i class="fa fa-envelope sColor" aria-hidden="true"></i>
                    </div>
                    <div class="flex-grow-1 ms-3">

                        <h5 class="text-start d-block fw-semibold text-dark">Email</h5>
                        <p class="text-start"> <a href="mailto:info@sendsii.com">info@sendsii.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Send money in four easy steps {END} -->

<app-general-info></app-general-info>