<div class="container-fluid text-center bg-breadCrumb py-5 ">
    <div class="row">
        <h1 class=" sColor ">How It Works</h1> </div>
</div>
<!-- Send money in four easy steps {START} -->
<section>
    <div class="container py-5">
      <div class="row">
        <div class="row">
          <div class="col-md-12 py-3 mx-auto text-center">
            <h1 class="text-center heading-md"> <span class="titleColor"> Send Money </span> in Four Easy Steps</h1>
          </div>
          <div class="row py-5">
            <div class="col-md-3 text-center">
              <div class="step-title py-3"> Step 1</div>
              <div class="circle-bg">
                <img class="py-5" src="assets/images/icons/choose-place.png" alt="choose-place" width="70">
              </div>
              <img class="check" src="assets/images/icons/check.png" alt="check">
              <div class="mt-4">
                <h2 class="titleColor m-0">Visit our authorised agents</h2>
                <p class="mt-3">Visit our authorised agents to process your money remittance.</p>
              </div>
            </div>
            <div class="col-md-3 text-center">
              <div class="step-title py-3">Step 2</div>
              <div class="circle-bg">
                <img class="py-5" src="assets/images/icons/choose-recipient.png" alt="choose-recipient" width="70">
              </div>
              <img class="check" src="assets/images/icons/check.png" alt="check">
              <div class="mt-4">
                <h2 class="titleColor m-0">Tell us the receiver's details</h2>
                <p class="mt-3">Tell us who and where you want to send the money to.</p>
              </div>
              <div class="center-divider"></div>
            </div>
            <div class="col-md-3 text-center">
              <div class="step-title py-3"> Step 3</div>
              <div class="circle-bg">
                <img class="py-5" src="assets/images/icons/choose-amount.png" alt="choose-place" width="70">
              </div>
              <img class="check" src="assets/images/icons/check.png" alt="check">
              <div class="mt-4">
                <h2 class="titleColor m-0">Choose Amount<br> And Currenty </h2>
                <p class="mt-3">Tell us how much you want to send in what currency.</p>
              </div>
              <div class="center-divider"></div>
            </div>
            <div class="col-md-3 text-center">
              <div class="step-title py-3"> Step 4</div>
              <div class="circle-bg">
                <img class="py-5" src="assets/images/icons/confirm-pay.png" alt="confirm-pay" width="70">
              </div>
              <img class="check" src="assets/images/icons/check.png" alt="check">
              <div class="mt-4">
                <h2 class="titleColor m-0">Confirm <br>And Pay</h2>
                <p class="mt-3">You will receive a confirmation of your transaction via SMS.</p>
              </div>
            </div>
    
          </div>
        </div>
      </div>
    </div>
  
  </section>
  <!-- Send money in four easy steps {END} -->