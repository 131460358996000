import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClientModule } from '@angular/common/http';
import { RefundCancellationPolicyComponent } from './common/refund-cancellation-policy/refund-cancellation-policy.component';
import { ServicesComponent } from './common/services/services.component';
import { ComplainthandlingComponent } from './common/complainthandling/complainthandling.component';
import { BecomeagentComponent } from './common/becomeagent/becomeagent.component';
import { FindagentComponent } from './common/findagent/findagent.component';
import { SecuretransferComponent } from './common/securetransfer/securetransfer.component';
import { AmlcomplianceComponent } from './common/amlcompliance/amlcompliance.component';
import { FastdeliveryComponent } from './common/fastdelivery/fastdelivery.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    RefundCancellationPolicyComponent,
    ServicesComponent,
    ComplainthandlingComponent,
    BecomeagentComponent,
    FindagentComponent,
    SecuretransferComponent,
    AmlcomplianceComponent,
    FastdeliveryComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    HeaderComponent,   
    FooterComponent,
    CarouselModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule
      ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
