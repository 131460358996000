<header>
  <nav class="navbar navbar-expand-lg bg-body-tertiary shadow-sm p-3">
    <div class="container">
      <img src="assets/images/logo.png" alt="logo" width="150" height="50" routerLink="/home" style="cursor: pointer;">

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse flex-grow-0" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item" routerLinkActive="activeTab" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link p-2 mx-3" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
              aria-current="page" routerLink="home">Home</a>
          </li>
          <li class="nav-item">
            <a  class="nav-link p-2 mx-3" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
              aria-current="page" routerLinkActive="activeTab" [routerLinkActiveOptions]="{exact: true}"
              routerLink="howitworks">How it Works</a>
          </li>
          <li class="nav-item">
            <a  class="nav-link p-2 mx-3" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
              aria-current="page" routerLinkActive="activeTab" [routerLinkActiveOptions]="{exact: true}"
              routerLink="services">Services</a>
          </li>
          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle p-2 mx-3" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Services
            </a>
            <ul class="dropdown-menu mx-3">
              <li><a class="dropdown-item" href="#">Agent Based Transfer</a></li>
              <li><a class="dropdown-item" href="#">Global Coverage</a></li>
              <li><a class="dropdown-item" href="#">Exchange Rates</a></li>
              <li><a class="dropdown-item" href="#">Send & Receive Money</a></li>
            </ul>
          </li> -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle p-2 mx-3" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Why SendSii
            </a>
            <ul class="dropdown-menu mx-3">
              <li><a class="dropdown-item" routerLink="secure-fast">Secure & Fast</a></li>
              <li><a class="dropdown-item" routerLink="aml-compliance">AML Compliance</a></li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link p-2 mx-3" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
              aria-current="page" routerLink="faq" routerLinkActive="activeTab"
              [routerLinkActiveOptions]="{exact: true}">FAQ's</a>
          </li>
          <li class="nav-item" routerLinkActive="activeTab" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link p-2 mx-3" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
              aria-current="page" routerLink="contactus">Contact</a>
          </li>
          <li class="nav-item" routerLinkActive="activeTab" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link p-2 mx-3" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
              aria-current="page" routerLink="find-agent">Find Agent</a>
          </li>
          <li class="nav-item agent-btn">
          <button type="submit" class="text-white" mat-raised-button color="primary"><a href="https://app.sendsii.com/" target="_blank"> Agent Login </a><i class="fa fa-external-link mx-2"></i></button>
          </li>
        </ul>
      </div>

    </div>
  </nav>
</header>