<div class="container-fluid text-center bg-breadCrumb py-5 ">
    <div class="row">
        <h1 class=" sColor ">Refund & Cancellation Policy</h1> </div>
</div>
<!-- Page content {START} -->
<section>
    <div class="container py-5">
      <div class="row">
        <div class="row">
          <div class="col-md-9 py-3">
            <p>Customers can request to cancel a transaction or seek a refund before the funds have been collected or paid out to the recipient, subject to applicable processing times and fees. Refunds are at the discretion of SendSii Ltd, provided legal and compliance criteria are met.</p>
            <p>If the requested refund is a mobile money transfer, only the principal amount can be refunded, not the commission, as SendSii Ltd has fulfilled its delivery obligation.</p>
          </div>
        </div>
      </div>
    </div>
  
  </section>
  <!-- Page content {END} -->