import { Component, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { apiservice } from 'src/app/services/api.service';

@Component({
  selector: 'app-findagent',
  templateUrl: './findagent.component.html',
  styleUrls: ['./findagent.component.css']
})


export class FindagentComponent  {
  form: FormGroup | undefined;
  showProgress: boolean | undefined;
  agentList: any;
  cityList: any;
  filterAgentList: any;

  constructor(private gsheetService: apiservice, private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      txtLocation: [null],
    });
    this.showProgress = true;
    this.getAgentList ();
  }
  
  getAgentList () {
    this.gsheetService.getAgentListData()
      .then((response) => {
        this.showProgress = false;
        this.agentList = response.data;
        console.log(this.agentList);
        
        this.filterAgentList = this.agentList;
        this.removeDuplicateCities();
      })
      .catch((error) => {
        this.showProgress = false;
        console.error(error.message);
      });
  }

  removeDuplicateCities () {
      if (this.agentList && this.agentList.length) {
        this.cityList = this.agentList.filter((item: { agentCity: any; }, i: any, arr: any[]) =>
          arr.findIndex((x) => (x.agentCity === item.agentCity)) === i);
      } else {
        this.cityList = [];
      }
  }

  onSelectLocation (event: any): void {
    this.filterAgentList = this.agentList;
    if (event.target.value !== "0: null") {
      this.filterAgentList = this.agentList.filter((list: { agentCity: any; }) => list.agentCity == event.target.value);
    }
  }

}
