import { Component } from '@angular/core';

@Component({
  selector: 'app-becomeagent',
  templateUrl: './becomeagent.component.html',
  styleUrls: ['./becomeagent.component.css']
})
export class BecomeagentComponent {

}
