import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from '../faq/faq.component';
import { AllMaterialModule } from 'src/app/shared/all-material.module';
import { RemittanceComponent } from '../remittance/remittance.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { GeneralInfoComponent } from '../general-info/general-info.component';

@Component({
  selector: 'app-aboutus',
  standalone: true,
  imports: [CommonModule,FaqComponent,AllMaterialModule,RemittanceComponent, CarouselModule,GeneralInfoComponent],
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent {

  customOptions: OwlOptions = {
    stagePadding: 50,
    loop: true,
    autoplay: true,
    center: true,
    autoWidth: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
}


