<!-- You choose how and we make it happen -->

      <section *ngIf="!changeTitle()">
        <div class="container py-5">
          <div class="row">
          
            <div class="col-md-12 py-3 mx-auto text-center">
              <p class="small-title"><strong>REMITTANCE METHODS</strong></p>
              <h1 class="text-center heading-md"> <span class="titleColor">You Choose How </span> And We Make It Happen</h1>
            </div>
            <div class="row py-5">
              <div class="col-md-4">
                <img class="img-fluid mx-auto d-block opacity-50 img-shadow" src="assets/images/cash-pickup.png"
                  alt="cash-picip" width="150">
                <div class="text-center mt-5 m-0">
                  <h5 class="m-0 titleColor text-uppercase"> Cash Collection</h5>
                  <p class="mt-3">Your recipient can pick up their remittance money before you leave our office through our trusted network of agents in all major towns worldwide.</p>
                </div>
      
              </div>
              <div class="col-md-4">
                <img class="img-fluid mx-auto d-block opacity-50 img-shadow" src="assets/images/Mobile-Money.png"
                  alt="mobile-money" width="150">
                <div class="text-center mt-5 m-0">
                  <h5 class="m-t titleColor text-uppercase">Mobile Money</h5>
                  <p class="mt-3">You can send money directly to your loved ones' mobile through one of our payment partners, EVC PLUS, SAHAL, ZAAD, MPESA, MTN and EBIRR.</p>
                </div>
      
              </div>
              <div class="col-md-4">
                <img class="img-fluid mx-auto d-block opacity-50 img-shadow" src="assets/images/bank-deposite.png"
                  alt="bank-desposite" width="150">
                <div class="text-center mt-5 m-0">
                  <h5 class="m-0 titleColor text-uppercase">Bank Transfer</h5>
                  <p class="mt-3">We can deliver money to Salaam Somalia Bank in Somalia, Salaam African Bank in Djibouti, Salaam Microfinance bank in Kenya and Waafi Bank Malaysia accounts. We are working on creating more partnerships across Africa and emerging markets.
                  </p>
                </div>
      
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- You choose how and we make it happen -->
    



  <!-- This for about us page {START} -->
<section *ngIf="changeTitle()">
    <div class="container py-5">
      <div class="row">
        <div  class="col-md-12 py-3 mx-auto text-center">
          <h1 class="text-center heading-md"> <span class="titleColor">Remittance</span></h1>
  
          <p class="py-3 m-02">We have partnered with Sendsii, one of the leading money transfer partners in the world <br> to deliver your money instantly through our three main delivery channels</p>
        </div>
  
        <div class="row py-3">
          <div class="col-md-4">
            <img class="img-fluid mx-auto d-block opacity-50 img-shadow" src="assets/images/Mobile-Money.png"
              alt="mobile-money" width="150">
            <div class="text-center mt-5 m-0">
              <h5 class="m-t titleColor text-uppercase">Cash Collection</h5>
              <p class="mt-3">Your recipient can pick their remittance money before you leave our office, through our network of agents in all major towns in the world.</p>
            </div>
  
          </div>
  
          <div class="col-md-4">
            <img class="img-fluid mx-auto d-block opacity-50 img-shadow" src="assets/images/cash-pickup.png"
              alt="cash-picip" width="150">
            <div class="text-center mt-5 m-0">
              <h5 class="m-0 titleColor text-uppercase">Mobile Money</h5>
              <p class="mt-3"> You can send money directly into your loved ones mobile through one of our payment partners, EVC PLUS, SAHAL, ZAAD, MPESA, M-TIGO, MTN and EBIRR.</p>
            </div>
  
          </div>
          <div class="col-md-4">
            <img class="img-fluid mx-auto d-block opacity-50 img-shadow" src="assets/images/bank-deposite.png"
              alt="bank-desposite" width="150">
            <div class="text-center mt-5 m-0">
              <h5 class="m-0 titleColor text-uppercase"> Bank Transfer</h5>
              <p class="mt-3"> We can deliver money into Salaama Somalia Bank accounts in Somalia. We are working on creating more partnership across Africa and emerging markets.
              </p>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- This for about us page {END} -->

