<footer style="background-color: #0e101a; color: white;">
  <div class="container py-3">
    <div class="row py-5">
      <div class="col-md-5">
        <img alt="footer logo" src="assets\images\logo.png" width="200" />
        <p class="text-white pt-3">
          SendSii Ltd is an Authorised Payment Institution, regulated by the FCA and HMRC to offer safe, efficient, and accessible money transfer services for individuals in the UK looking to support their loved ones in their home countries. Our dedication to excellence and customer satisfaction has established us as a trusted partner in the financial services sector.
        </p>
        <a href="#" class="border p-2" routerLink="aboutus" routerLinkActive="active">Read more...</a>
 
      </div>
      <div class="col-md-7">
        <div class="row justify-content-around">
          <div class="col-md-4">
            <h4 class="widgettitle">Quick Links</h4>
            <div>
              <ul class="list-inline mt-5">              
                <li class="list-item ">
                  <a class="nav-link ps-0" routerLink="/home" routerLinkActive="active"> Home</a>
                </li>
                <li class="list-item ">
                  <a class="nav-link ps-0 " routerLink="/howitworks">How it Works</a>
                </li>
                <li class="list-item ">
                  <a class="nav-link ps-0" routerLink="/services">Services</a>
                </li>
                <li class="list-item ">
                  <a class="nav-link ps-0" routerLink="/aboutus" routerLinkActive="active">About Us</a>
                </li>
                <li class="list-item ">
                  <a class="nav-link ps-0" routerLink="/contactus" routerLinkActive="active">Customer Support</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4">
            <h4 class="widgettitle">Legals</h4>
            <div>
              <ul class="list-inline mt-5">              
                <li class="list-item ">
                  <a class="nav-link ps-0" routerLink="/aml-compliance">AML Compliance</a>
                </li>
                <li class="list-item ">
                  <a class="nav-link ps-0" routerLink="/complaint-handling" >Complaint Handling</a>
                </li>
                <li class="list-item ">
                  <a class="nav-link ps-0" routerLink="/refund-cancellation-policy" >Refund & Cancellation Policy</a>
                </li>
                <li class="list-item ">
                  <a class="nav-link ps-0" routerLink="/agent-signup" >Become an Agent </a>
                </li>
                <li class="list-item ">
                  <a class="nav-link ps-0" routerLink="/find-agent" >Find Agent</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4">
            <h4 class="widgettitle">Contact Us</h4>
            <div>
              <ul class="list-inline mt-5">
                <li class="list-item "><i class="fa fa-map-marker sColor" aria-hidden="true"></i> 250 Kilburn High Road London, NW6 2BS</li>
                <li class="list-item ">
                <a class="nav-link ps-0"  href="mailto:info@sendsii.com" routerLinkActive="active"><i class="fa fa-envelope sColor" aria-hidden="true"></i> info@sendsii.com</a>
                </li>
                <li class="list-item ">
                  <a class="nav-link ps-0"  href="tel:+442033710202" routerLinkActive="active"><i class="fa fa-phone sColor" aria-hidden="true"></i>
                     +4420 3371 0202</a>
                </li>
            
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid pt-3" style="background: #b10a6b;">
    <div class="row">
      <p class="text-white text-center">© Copyright 2020 - {{currentYear}}. SendSii All Righht reserved.</p>
    </div>
  </div>
</footer>