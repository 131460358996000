import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-remittance',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './remittance.component.html',
  styleUrls: ['./remittance.component.css']
})
export class RemittanceComponent  implements OnInit {

  remittanceTitle:any
  constructor (private router: Router) {

  }
  // *ngIf="remittanceTitle === 'noe'"
  ngOnInit(){

    // this.remittanceTitle = this.router.navigateByUrl("aboutus")
    // console.log( this.remittanceTitle);
    this.changeTitle();
  
}

changeTitle() : boolean {
  return this.router.url === '/aboutus';


}


}
