<div class="container-fluid text-center bg-breadCrumb py-5 ">
    <div class="row">
        <h1 class=" sColor ">AML Compliance</h1> </div>
</div>
<!-- Page content {START} -->
<section>
    <div class="container py-5">
      <div class="row">
        <div class="row">
          <div class="col-md-9 py-3">
            <p>SendSii Ltd is authorised and regulated by both the <strong>Financial Conduct Authority (FCA) and His Majesty Revenue & Customs (HMRC)</strong>, SendSii adheres to strict compliance protocols, ensuring safe, legal, and transparent money transfer services. You can depend on us to deliver your funds safely every time.</p>
            <p>All transfers comply with UK <strong>Anti-Money Laundering (AML)</strong> laws, preventing illegal financial activities. We use advanced monitoring systems to detect and report suspicious transactions in real time.</p>
            <p>SendSii adheres to <strong>Counter-Terrorist Financing (CTF)</strong> regulations to ensure that funds are not misused for illicit purposes, and all suspicious activities are reported to the authorities, including the <strong>National Crime Agency (NCA)</strong>.</p>
            <p>Risk Assessment: We conduct comprehensive risk assessments to identify and understand the potential risks of money laundering and terrorist financing associated with our services. This enables us to implement effective control measures and continuously monitor and mitigate these risks.</p>
            <p>Customers must complete the <strong>Know Your Customer (KYC)</strong> process by providing valid identification documents to ensure identity verification before initiating a transaction, meeting strict UK compliance standards.</p>
            <p>Enhanced Due Diligence (EDD): When higher risk is identified, we implement enhanced due diligence measures to better understand the customer’s background and the purpose and nature of their transactions. This ensures we can effectively mitigate the associated risks.</p>
            <p>Training and Awareness: We prioritise ongoing training and awareness programs for our employees and agents to ensure they are well-informed about AML/CFT regulations, red flags, and reporting obligations. This empowers them to effectively recognise and report suspicious activities.</p>
            <p>Technology and Systems: We utilise advanced technology and robust systems to improve our AML/CFT compliance capabilities. Our systems include transaction monitoring tools and data analytics to identify and investigate irregular patterns or activities.</p>
            <p>We strictly adhere to <strong>OFAC, UK, EU, and UN sanctions</strong> regulations, meaning transfers to or from sanctioned individuals, entities, or countries are not allowed. Transactions are screened to ensure compliance with international sanctions.</p>
            <p>Every transaction is monitored in real-time to ensure compliance with UK and international regulations. Unusual or large transactions are flagged and assessed for potential risks, safeguarding against fraud or money laundering.</p>
            <p>Reporting Suspicious Transactions: We are committed to promptly reporting suspicious transactions to the appropriate authorities. We collaborate closely with law enforcement agencies and regulatory bodies to aid in the prevention and detection of financial crimes.</p>
          </div>
        </div>
      </div>
    </div>
  
  </section>
  <!-- Page content {END} -->