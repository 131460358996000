<section>
  <div class="container-fluid py-5 bg-sendMoney ">
    <div class="row">
      <div class="col-md-10 mx-auto">
        <div class="row py-4">
          <div class="col-md-7 mt-md-3">
            <h1 class="heading-lg  "> <span class="titleColor clip-text clip-text_one"> Send Money </span> to <br><span
                class="clip-text  clip-text_revert"> Your Loved Ones</span></h1>
            <p class="py-2">SendSii Ltd is your trusted money transfer services provider, offering fast, reliable, and secure transfers from the UK to worldwide destinations. </p>
            <p>With a network of trusted agents all over the UK and strong reputable pay-out partners in major jurisdictions, we make sending money simple, whether you’re supporting loved ones or conducting international business.</p>
          </div>

          <div class="col-md-5 mt-sm-2">
            <mat-card>
              <mat-card-content>
                <div class="steps text-center mt-3">
                  <h2 class="titleColor m-0">Check Rates</h2>
                </div>
                <div class="p-3 py-4">
                  <form [formGroup]="form" *ngIf="form"  (ngSubmit)="onSubmit()" >
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Send</span>
                      </div>

                      <input value="{{theySendAmnt | number : '1.2-2'}}" id="sendingCountry" name="sendingCountry" type="text" class="form-control"
                        aria-label="Amount (to the nearest pound)" formControlName="sendingCountry">
                      <div class="input-group-append">
                        <span class="input-group-text-symbol">£</span>
                      </div>
                    </div>

                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Receive</span>
                      </div>
                      <input value="{{theyReceivedAmnt | number : '1.2-2'}}" id="payingCountry" name="payingCountry" type="text" class="form-control"
                        aria-label="Amount (to the nearest dollar)" formControlName="payingCountry">
                      <div class="input-group-append">
                        <span class="input-group-text-symbol">$</span>
                      </div>
                    </div>

                    <ul class="calculator-details d-none">
                      <li class="fees">Fees <span class="value"></span> </li>
                      <li class="pay">You pay<span class="value"></span></li>
                      <li class="recv">They get <span class="value"></span></li>
                    </ul>
                  
                    <p  class="text-muted text-center mt-3 mb-0"><span>*Sending Rate </span> <span class="fw-bold"> 1 </span><span c>GBP</span> = <span class="quote-amount"></span> <span class="fw-bold"> {{exchangeRate}} </span>USD</p>   
 <p  class="text-muted text-center mb-3">BXD = <span class="fw-bold"> {{bxdRate}} </span> GBP</p>         
                
                       <div class="bg-light-Grey rounded" *ngIf="calculationProgress">
                        <ul class="list-unstyled p-2">
                          <!-- <li class="py-2 fs12 fw-normal">BXD <span class="float-end">{{bxdRate}}  GBP</span></li>
                          <mat-divider></mat-divider> -->
                          <li class="py-2 fs12 fw-normal">Fee <span class="float-end"> {{feeAmnt | number : '1.2-2' }} GBP </span>  </li>
                          <mat-divider></mat-divider>
                          <li class="py-2 fs12 "> Total to pay  <span class="float-end mx-1 mt-1"> GBP </span>  <span class="float-end fw-bold fs-6"> {{totalToPay | number : '1.2-2'}}  </span>  </li>
                        </ul>

                       </div>

            <mat-spinner  [diameter]="20" mode="indeterminate" *ngIf="showProgress">   </mat-spinner>
                    <!-- <button routerLink="/find-agent"  type="submit" class="mt-3 mx-auto w-100 text-white text-uppercase sendMoneyText" mat-raised-button
                      color="primary"> Find Agent</button> -->
                  </form>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>



<!-- Send money in four easy steps {START} -->
<section>
  <div class="container py-5 steps">
    <div class="row">
      <div class="col-md-12 py-3 mx-auto text-center">
        <p class="small-title"><strong>HOW IT WORKS</strong></p>
        <h1 class="text-center heading-md"> <span class="titleColor"> Send Money </span> in Four Easy Steps</h1>
      </div>
      <div class="row py-5">
        <div class="col-md-3 text-center">
          <div class="step-title py-3"> Step 1</div>
          <div class="circle-bg">
            <img class="py-5" src="assets/images/icons/choose-place.png" alt="choose-place" width="70">
          </div>
          <img class="check" src="assets/images/icons/check.png" alt="check">
          <div class="mt-4">
            <h2 class="titleColor m-0">Visit our authorised agents</h2>
            <p class="mt-3">Visit our authorised agents to process your money remittance.</p>
          </div>
        </div>
        <div class="col-md-3 text-center">
          <div class="step-title py-3">Step 2</div>
          <div class="circle-bg">
            <img class="py-5" src="assets/images/icons/choose-recipient.png" alt="choose-recipient" width="70">
          </div>
          <img class="check" src="assets/images/icons/check.png" alt="check">
          <div class="mt-4">
            <h2 class="titleColor m-0">Tell us the receiver's details</h2>
            <p class="mt-3">Tell us who and where you want to send the money to.</p>
          </div>
          <div class="center-divider"></div>
        </div>
        <div class="col-md-3 text-center">
          <div class="step-title py-3"> Step 3</div>
          <div class="circle-bg">
            <img class="py-5" src="assets/images/icons/choose-amount.png" alt="choose-place" width="70">
          </div>
          <img class="check" src="assets/images/icons/check.png" alt="check">
          <div class="mt-4">
            <h2 class="titleColor m-0">Choose Amount<br> And Currenty </h2>
            <p class="mt-3">Tell us how much you want to send in what currency.</p>
          </div>
          <div class="center-divider"></div>
        </div>
        <div class="col-md-3 text-center">
          <div class="step-title py-3"> Step 4</div>
          <div class="circle-bg">
            <img class="py-5" src="assets/images/icons/confirm-pay.png" alt="confirm-pay" width="70">
          </div>
          <img class="check" src="assets/images/icons/check.png" alt="check">
          <div class="mt-4">
            <h2 class="titleColor m-0">Confirm <br>And Pay</h2>
            <p class="mt-3">You will receive a confirmation of your transaction via SMS.</p>
          </div>
        </div>

      </div>
    </div>
  </div>

</section>
<!-- Send money in four easy steps {END} -->




<!-- Send money to your friends and family {START}  -->
<section>
  <div class="container-fluid py-5 bg-lightGrey">
    <div class="row">
      <div class="col-md-10 mx-auto ">
        <div class="row py-5">
          <div class="col-md-4 me-5">
            <img class="img-fluid " src="assets/images/payment-featured.png" alt="send-money-your-friend" width="400"
              height="300">
          </div>

          <div class="col-md-7 my-auto">
            <h1 class="text-start heading-md"> <span class="titleColor"> Secure </span> Transfer Solutions</h1><br>
            <p>SendSii offers worldwide reach with secure transfer solutions. From the UK, we deliver funds to over 100 countries, providing local currency pay-outs at competitive exchange rates. Our extensive pay-out network includes banks, mobile wallets, and local agents.</p>
            <p>
              Enjoy competitive exchange rates and transparent fees. Use our Exchange Rate Calculator to check rates before sending money. Rates are updated daily to give you the best possible deal.
            </p>
            <p>SendSii offers both sending and receiving services. You can send funds to family, pay international bills, or manage overseas expenses quickly and efficiently. Recipients can collect funds through our agent network through cash pick-up.</p>
          </div>
        </div>
      </div>

    </div>

  </div>
</section>
<!-- Send money to your friends and family  {END} -->
<app-remittance></app-remittance>


<app-general-info></app-general-info>