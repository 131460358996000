import { Component } from '@angular/core';

@Component({
  selector: 'app-complainthandling',
  templateUrl: './complainthandling.component.html',
  styleUrls: ['./complainthandling.component.css']
})
export class ComplainthandlingComponent {

}
