import { Component } from '@angular/core';

@Component({
  selector: 'app-refund-cancellation-policy',
  templateUrl: './refund-cancellation-policy.component.html',
  styleUrls: ['./refund-cancellation-policy.component.css']
})
export class RefundCancellationPolicyComponent {

}
