import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GeneralInfoComponent } from '../general-info/general-info.component';

@Component({
  selector: 'app-contactus',
  standalone: true,
  imports: [CommonModule,RouterModule,GeneralInfoComponent],
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent {

}
