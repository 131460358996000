import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { RouterModule } from '@angular/router';
import { AllMaterialModule } from 'src/app/shared/all-material.module';
import { RemittanceComponent } from '../remittance/remittance.component';
import { GeneralInfoComponent } from '../general-info/general-info.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { apiservice } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, of, Subscription, switchMap } from 'rxjs';
import { taxSlab } from 'src/assets/content/static-data';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, RouterModule, AllMaterialModule, RemittanceComponent, GeneralInfoComponent, HttpClientModule, FormsModule, ReactiveFormsModule],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  exchangeRate: any | undefined;
  form: FormGroup | undefined;
  youSendAmountSubscription: Subscription | undefined;
  theyRecieveAmountSubscription: Subscription | undefined;
  theyReceivedAmnt: number | undefined;
  theySendAmnt: number | undefined;
  showProgress: boolean = false;
  sendAmnt: number | undefined;
  recieveAmnt: number | undefined;
  bxdRate: number | undefined;
  feesInPercentage: number | undefined;
  totalToPay: number | undefined;
  feeAmnt: number | undefined;
  finalTaxSlabFeeAmnt: number | undefined;
  calculationProgress: boolean = false;
  taxSlabData: any;
  taxSlabKey: any;
  taxSlabVal: any;

  constructor(private gsheetService: apiservice, private fb: FormBuilder) { }

  ngOnInit() {
    this.taxSlabData;
    this.form = this.fb.group({
      sendingCountry: [null, Validators.required],
      payingCountry: [null, Validators.required],
    });

    this.getExchangeRate();
    this.youSendAmountSubscription = this.form.controls['sendingCountry'].valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged(), switchMap(num => of(num)))
      .subscribe(
        (value: any) => {
          this.sendAmnt = Number(value);
          this.theyReceivedAmnt = undefined;
          this.recieveAmnt = undefined;
          this.getExchangeRate();

        }
      );

    this.theyRecieveAmountSubscription = this.form.controls['payingCountry'].valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged(), switchMap(num => of(num)))
      .subscribe(
        (value: any) => {
          this.recieveAmnt = Number(value);
          this.theySendAmnt = undefined;
          this.sendAmnt = undefined;
          this.getExchangeRate();
        }
      );
  }


  getExchangeRate(): void {
    this.showProgress = true;
    this.calculationProgress = false;
    this.feeAmnt = undefined;
    this.totalToPay = undefined;
    this.gsheetService.getData()
      .then((response) => {
        this.showProgress = false;
        this.exchangeRate = response.data[0].exchangeRate;
        this.bxdRate = response.data[0].bxd;
        this.taxSlabData = Object.entries(response.data[0]).map((e) => ({ [e[0]]: e[1] }));

        if (this.sendAmnt) {
          this.calculationProgress = true;
          this.theyReceivedAmnt = this.sendAmnt! * this.exchangeRate;
          this.calculateFeeAmount(this.theyReceivedAmnt);
          this.feeAmnt = this.sendAmnt * this.feesInPercentage! / 100;
          this.totalToPay = (this.sendAmnt + this.feeAmnt);
        }

        if (this.recieveAmnt) {
          this.calculationProgress = true;
          this.theySendAmnt = this.recieveAmnt! * 1 / this.exchangeRate;
          this.calculateFeeAmount(this.recieveAmnt);
          this.feeAmnt = this.theySendAmnt! * this.feesInPercentage! / 100;
          this.totalToPay = (this.theySendAmnt! + this.feeAmnt!);
        }
      })
      .catch((error) => {
        this.showProgress = false;
        console.error(error.message);
      });

  }

  async calculateFeeAmount(receiveAmount: number) {
    for (let i = 0; i < this.taxSlabData!.length; i++) {
      var currentSlab = this.taxSlabData![i];
      this.taxSlabKey = Object.keys(currentSlab);
      this.taxSlabVal = Object.values(currentSlab);
      if (receiveAmount! <= Number(this.taxSlabKey[0])) {
        this.feesInPercentage = this.taxSlabVal[0];
        break;
      }
      if (receiveAmount! > 5000) {
        this.feesInPercentage = 2;
        break;
      }
    }
  }

  onSubmit() { }

}


