import { Component } from '@angular/core';

@Component({
  selector: 'app-amlcompliance',
  templateUrl: './amlcompliance.component.html',
  styleUrls: ['./amlcompliance.component.css']
})
export class AmlcomplianceComponent {

}
