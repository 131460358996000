import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class apiservice {

  googleSheetURL: any = "https://script.google.com/macros/s/AKfycbwkDAf72a7zkTTlg21bFIRqm3QNrgIou3N1bQztaEDUJFy_8jgY90XqWTmlMxHxr9xS/exec";
  agentListURL: any = "https://script.google.com/macros/s/AKfycbwfR22MbD6hZPWFBAMd80H2aw4CJPGzJS4ixdmNa3cccabpnedutI5Ku7xaSG3YLXkl/exec";

  constructor(private http: HttpClient) { }

  public getData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.googleSheetURL).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  public getAgentListData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.agentListURL).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }


}
