<div class="container-fluid text-center bg-breadCrumb py-5 ">
    <div class="row">
        <h1 class=" sColor ">FAQ's</h1>
    </div>
</div>
<div class="container my-5">
    <div class="row">
        <div class="col-md-9">

            <mat-accordion>
                <mat-expansion-panel class="mb-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            How safe is sending money with SendSii Ltd?
                        </mat-panel-title>

                    </mat-expansion-panel-header>
                    <p><strong>Regulatory Compliance:</strong> SendSii Ltd operates under the oversight of the Financial
                        Conduct Authority (FCA) in the UK, ensuring full compliance with UK regulations, including
                        Anti-Money Laundering (AML) and Counter-Terrorist Financing (CFT) laws. This regulatory
                        framework helps protect customers from fraud, safeguards their funds, and ensures all
                        transactions are legitimate.</p>
                    <p><strong>Secure Transactions:</strong> SendSii uses advanced encryption technology to secure
                        customer data and transaction information. This guarantees that all personal and financial
                        details are managed safely throughout the transfer process.</p>
                    <p><strong>Transaction Monitoring:</strong> SendSii monitors transactions in real-time to detect and
                        prevent fraud and suspicious activities. This includes screening for potential money laundering
                        and fraudulent actions, and ensuring that transactions comply with both international and local
                        financial regulations.</p>
                    <p><strong>Agent Network:</strong> SendSii operates through a trusted network of agents who adhere
                        to strict procedures for customer identification and transaction processing. This guarantees
                        that every transfer is conducted securely and reliably.</p>
                    <p><strong>Customer Data Protection:</strong> SendSii complies with the General Data Protection
                        Regulation (GDPR), ensuring that customer information is stored and processed securely,
                        providing full protection of personal data.</p>
                </mat-expansion-panel>
                <mat-expansion-panel class="mb-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            How do I send money through SendSii?
                        </mat-panel-title>

                    </mat-expansion-panel-header>
                    <p> <strong>Step 1:</strong> Visit one of our certified agents across the UK.</p>
                    <p><strong>Step 2:</strong> Provide the recipient details, transfer amount, and any required ID and
                        information such as proof of address and /or source of funds for compliance.</p>
                    <p><strong>Step 3:</strong> Our agent will securely process the transaction, providing confirmation
                        and tracking details in an official receipt.</p>
                    <p><strong>Step 4:</strong> The recipient will collect funds from a pay-out agent or receives the
                        money directly to their mobile wallet or bank account.</p>
                </mat-expansion-panel>

                <mat-expansion-panel class="mb-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            What identification is required for a money transfer?
                        </mat-panel-title>

                    </mat-expansion-panel-header>
                    <p><strong>Government-issued photo ID:</strong> This can include a passport, driver’s license, or
                        national ID card. The ID must be valid and current, showing the customer’s name, photo, and date
                        of birth.</p>
                    <p><strong>Proof of Address:</strong> A utility bill, bank statement, or rental agreement showing
                        the customer’s name and residential address, usually dated within the last three months.</p>
                    <p><strong>Additional Identification:</strong> In some cases, for higher-value transactions or when
                        dealing with large sums of money, customers may be required to provide proof of income or the
                        source of funds as part of Enhanced Due Diligence (EDD).</p>
                </mat-expansion-panel>

                <mat-expansion-panel class="mb-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            How long does it take for funds to reach the recipient?
                        </mat-panel-title>

                    </mat-expansion-panel-header>
                    <p>Mobile Money - Instantly <br>
                        Cash pick- up - immediately <br>
                        Bank Transfer - Instantly
                        </p>
                </mat-expansion-panel>

                <mat-expansion-panel class="mb-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            What are the fees for sending money?
                        </mat-panel-title>

                    </mat-expansion-panel-header>
                    <p>Different principal amounts incur varying percentage fees. We apply lower fees progressively, meaning larger principal amounts benefit from lower percentage fees.</p>
                </mat-expansion-panel>

            </mat-accordion>
        </div>
    </div>
</div>