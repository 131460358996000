<div class="container-fluid text-center bg-breadCrumb py-5 ">
    <div class="row">
        <h1 class=" sColor ">Secure Transfer</h1> </div>
</div>
<!-- Page content {START} -->
<section>
    <div class="container py-5">
      <div class="row">
        <div class="row">
          <div class="col-md-9 py-3">
            <p>Your security is our top priority. All transactions are encrypted and safeguarded with cutting-edge technology. We adhere to the latest UK regulatory standards, including Anti-Money Laundering (AML) and Counter-Terrorist Financing (CTF) regulations</p>
            <p><strong>Regulated and Secure:</strong> SendSii Ltd is regulated by the Financial Conduct Authority (FCA), ensuring adherence to UK financial regulations and providing secure money transfer services.</p>
            <p><strong>Global Reach:</strong> SendSii allows customers to send money to over 100 countries, thanks to its extensive network of agents and pay-out partners, ensuring global coverage and accessibility.</p>
            <p><strong>Competitive Exchange Rates:</strong> SendSii provides competitive exchange rates, allowing customers to receive greater value when transferring money internationally.</p>
            <p><strong>Transparent Fees:</strong> There are no hidden fees. Customers are informed of all costs upfront, including any service or currency conversion fees, making the process clear and predictable.</p>
            <p><strong>Fast and Reliable Transfers:</strong> SendSii offers fast transfers, with many transactions completed in minutes, depending on the destination and pay-out options.</p>
            <p><strong>Agent-Based Convenience:</strong> Customers can utilise local agents for in-person transfer assistance, providing personalised service and support at convenient locations throughout the UK.</p>
            <p><strong>Strict Compliance with AML and CFT:</strong> The service adheres to strict Anti-Money Laundering (AML) and Counter-Terrorist Financing (CFT) policies, ensuring that every transaction is both legal and secure.</p>
            <h3 class="mt-5 strong">Fast Transfer</h3>
            <p>We understand that timely transfers are critical. That’s why we work with a fast and reliable pay-out network, ensuring recipients receive their money quickly—often within minutes, depending on the destination.</p>
          </div>
        </div>
      </div>
    </div>
  
  </section>
  <!-- Page content {END} -->