<section>
  <div class="container pb-5 steps">
    <div class="row">
      <div class="col-md-12 py-3 mx-auto text-center">
        <p class="small-title"><strong>WE WORK WITH</strong></p>
        <h1 class="text-center heading-md"> <span class="titleColor"> Our </span> Partners</h1>
      </div>
      <div class="row py-5">
        <div class="col-md-2">
          <img src="assets/images/partners/evcplus.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/partners/zaad.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/partners/golis.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/partners/mpesa.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/partners/mtn-uganda.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/partners/ebirr.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/partners/taaj.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/partners/sundusexchange.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/partners/rasmipay.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/partners/sunduspay.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/partners/salaam.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/partners/waafibank.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/partners/smb.jpg">
        </div>
      </div>
    </div>
  </div>
</section>