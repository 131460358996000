<div class="container-fluid text-center bg-breadCrumb py-5">
    <div class="row">
        <h1 class="sColor">About us</h1>
    </div>
</div>

<div class="container py-5">
    <div class="row">
        <div class="col-md-6">
            <h1 class="py-3 titleColor ">Welcome to SendSii Ltd</h1>
            <p>SendSii Ltd is your trusted money transfer services provider, offering fast, reliable, and
                secure transfers from the UK to worldwide destinations. </p>
            <p>With a network of trusted agents all over the UK and strong reputable pay-out partners in major
                jurisdictions, we make sending money simple, whether you’re supporting loved ones or conducting
                international business.</p>
            <p>
                SendSii Ltd is an Authorised Payment Institution, regulated by the FCA and HMRC to offer safe,
                efficient, and accessible money transfer services for individuals in the UK looking to support their
                loved ones in their home countries. Our dedication to excellence and customer satisfaction has
                established us as a trusted partner in the financial services sector.
            </p>

        </div>
        <div class="col-md-6">
            <img class="img-fluid" src="assets/images/aboutus.png" alt="aboutus">
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-6">
            <!-- Vision -->

            <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                    <img src="assets/images/icons/vission.png" alt="vission" width="40">
                </div>
                <div class="flex-grow-1 ms-3"><h5>VISION</h5> </div>
            </div>

            <p class="ms-5">
                Positively impact people’s lives worldwide by enabling secure, fast, affordable, and safe money sharing.
            </p>
        </div>
        <div class="col-md-6">
            <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                    <img src="assets/images/icons/mission.png" alt="Mission" width="40">
                </div>
                <div class="flex-grow-1 ms-3"> <h5>MISSION</h5>  </div>
            </div>
            <p class="ms-5">Our mission is to simplify and enhance the process of sending and receiving money. We provide a seamless experience by combining cutting-edge technology with personalised service to meet the diverse needs of our clients. We offer a comprehensive range of money transfer services tailored to the unique requirements of our customers. This includes international money transfers, an extensive agent network, and fully licensed partners in most regions, including Africa, the Middle East, Europe, North America, and Australia.</p>
        </div>
    </div>
</div>

<app-general-info></app-general-info>