import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AllMaterialModule } from 'src/app/shared/all-material.module';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [CommonModule,RouterModule,AllMaterialModule],
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent {
  panelOpenState = false;
}
