<div class="container-fluid text-center bg-breadCrumb py-5 ">
    <div class="row">
        <h1 class=" sColor ">Our Services</h1>
    </div>
</div>
<!-- Page content {START} -->
<section>
    <div class="container py-5">
        <div class="row">
            <div class="col-md-6 py-3 my-auto">
                <h4 class="pColor py-3">Agent Network and Compliance</h4>
                <p>Our primary service is offline, agent-based money transfers. Whether you’re sending to urban or rural
                    areas, our network ensures the money reaches where it’s intended. Agents offer personalised
                    assistance, ensuring your transaction is handled with care.</p>
                <p>All agents within the SendSii network are trained and required to follow strict compliance protocols,
                    including conducting KYC, AML checks, and adhering to customer protection laws to ensure that
                    transfers are secure and transparent.</p>
            </div>
            <div class="col-md-6 text-center">
                <img src="assets/images/agent-network.png" class="img-fluid border p-3 w-75">
            </div>
        </div>
        <div class="row my-5">
            <div class="col-md-6 text-center">
                <img src="assets/images/aboutus-globe.jpg" class="img-fluid border p-3 w-50">
            </div>
            <div class="col-md-6 py-3 my-auto">
                <h4 class="pColor py-3">Global Coverage</h4>
                <p>SendSii offers worldwide reach with secure transfer solutions. From the UK, we deliver funds to over 100 countries, providing local currency pay-outs at competitive exchange rates. Our extensive pay-out network includes banks, mobile wallets, and local agents.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 py-3 my-auto">
                <h4 class="pColor py-3">Exchange Rates</h4>
                <p>Enjoy competitive exchange rates and transparent fees. Use our Exchange Rate Calculator to check rates before sending money. Rates are updated daily to give you the best possible deal.</p>
            </div>
            <div class="col-md-6 text-center">
                <img src="assets/images/exchange-rate.webp" class="img-fluid border p-3 w-50">
            </div>
        </div>
        <div class="row my-5">
            <div class="col-md-6 text-center">
                <img src="assets/images/payment-featured.png" class="img-fluid border p-3 w-75">
            </div>
            <div class="col-md-6 py-3 my-auto">
                <h4 class="pColor py-3">Send and Receive</h4>
                <p>SendSii offers both sending and receiving services. You can send funds to family, pay international bills, or manage overseas expenses quickly and efficiently. Recipients can collect funds through our agent network in the form of cash pick-up.</p>
                <button routerLink="/find-agent"  type="submit" class="btn btn-success" mat-raised-button
                      color="primary"> Find Agent</button>
            </div>
        </div>
    </div>

</section>
<!-- Page content {END} -->